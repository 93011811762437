import React from 'react'
import Img from 'gatsby-image'
import { GatsbyImageProps } from 'gatsby-image'
import styles from './Image.module.css'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

type ImageProps = GatsbyImageProps

const Image: React.FC<ImageProps> = props => {
  return <Img {...props} placeholderClassName={styles.placeholder} />
}

export default Image
